var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container",
    attrs: {
      "id": "dashboard"
    }
  }, [_vm.isNoRole ? _c('div', {
    staticClass: "card"
  }, [_c('p', [_vm._v("Derzeit dem User keine Rolle und keine Rechte zugewiesen!")]), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/useraccount"
    }
  }, [_vm._v("Mein Konto")])], 1) : _vm._e(), _vm._isAdmin || _vm._isSv ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('h3', [_vm._v("SV-Urgenzen")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th'), _vm._l(_vm.urgencies.dayKeys, function (day) {
    return _c('th', [_vm._v(_vm._s(day))]);
  })], 2)]), _c('tbody', _vm._l(_vm.urgencies.byUsers, function (user, contactId) {
    var _vm$_svNamesByCrmCont, _vm$_svNamesByCrmCont2, _vm$_svNamesByCrmCont3;
    return _c('tr', [_c('td', [!contactId ? _c('span', [_vm._v("'Kein Kontakt vorhanden'")]) : (_vm$_svNamesByCrmCont = _vm._svNamesByCrmContactId) !== null && _vm$_svNamesByCrmCont !== void 0 && _vm$_svNamesByCrmCont[contactId] ? _c('span', [_c('span', [_vm._v(_vm._s((_vm$_svNamesByCrmCont2 = _vm._svNamesByCrmContactId[contactId]) === null || _vm$_svNamesByCrmCont2 === void 0 ? void 0 : _vm$_svNamesByCrmCont2.firstname) + " ")]), _c('span', [_vm._v(_vm._s((_vm$_svNamesByCrmCont3 = _vm._svNamesByCrmContactId[contactId]) === null || _vm$_svNamesByCrmCont3 === void 0 ? void 0 : _vm$_svNamesByCrmCont3.lastname))])]) : _c('span', [_vm._v(_vm._s(contactId.substring(0, 12)))])]), _vm._l(_vm.urgencies.dayKeys, function (day) {
      return _c('td', [_vm._v(_vm._s(user[day]))]);
    })], 2);
  }), 0)])]), _c('hr'), _c('div', {
    staticClass: "item"
  }, [_c('h3', [_vm._v("Versicherer-Urgenzen")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th'), _vm._l(_vm.urgencies.dayKeys, function (day) {
    return _c('th', [_vm._v(_vm._s(day))]);
  })], 2)]), _c('tbody', _vm._l(_vm.urgencies.byInsurers, function (insurer, accountid) {
    var _vm$urgencies, _vm$urgencies$insurer;
    return _c('tr', [_c('td', [_vm._v(_vm._s(((_vm$urgencies = _vm.urgencies) === null || _vm$urgencies === void 0 ? void 0 : (_vm$urgencies$insurer = _vm$urgencies.insurerById) === null || _vm$urgencies$insurer === void 0 ? void 0 : _vm$urgencies$insurer[accountid]) || accountid))]), _vm._l(_vm.urgencies.dayKeys, function (day) {
      return _c('td', [_vm._v(_vm._s(insurer[day]))]);
    })], 2);
  }), 0)])]), _c('hr'), _c('div', {
    staticClass: "item"
  }, [_c('h3', [_vm._v("Aktuelle Urgenzen nach Akt (letzte 10)")]), _c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.urgencies.byCommissions, function (count, commissionId) {
    var _vm$urgencies$commiss, _vm$urgencies$commiss2, _vm$urgencies$commiss3, _vm$urgencies$insurer2, _vm$urgencies$commiss4, _vm$urgencies$commiss5;
    return _c('tr', [_c('td', [_c('a', {
      attrs: {
        "href": "/#/crmakt/".concat(((_vm$urgencies$commiss = _vm.urgencies.commissionById) === null || _vm$urgencies$commiss === void 0 ? void 0 : (_vm$urgencies$commiss2 = _vm$urgencies$commiss[commissionId]) === null || _vm$urgencies$commiss2 === void 0 ? void 0 : _vm$urgencies$commiss2.commissionNr) || '')
      }
    }, [_vm._v(_vm._s(((_vm$urgencies$commiss3 = _vm.urgencies.commissionById) === null || _vm$urgencies$commiss3 === void 0 ? void 0 : _vm$urgencies$commiss3[commissionId].title) || commissionId))])]), _c('td', [_vm._v(_vm._s(((_vm$urgencies$insurer2 = _vm.urgencies.insurerById) === null || _vm$urgencies$insurer2 === void 0 ? void 0 : _vm$urgencies$insurer2[(_vm$urgencies$commiss4 = _vm.urgencies.commissionById) === null || _vm$urgencies$commiss4 === void 0 ? void 0 : (_vm$urgencies$commiss5 = _vm$urgencies$commiss4[commissionId]) === null || _vm$urgencies$commiss5 === void 0 ? void 0 : _vm$urgencies$commiss5.insurerId]) || ''))]), _c('td', [_vm._v(_vm._s(count))])]);
  }), 0)])])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h1', [_vm._v("Urgenzen Dashboard")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Akt")]), _c('th', [_vm._v("Auftraggeber")]), _c('th', [_vm._v("Urgenzen")])])]);
}];
export { render, staticRenderFns };